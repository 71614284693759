import FormPage from '@/components/FormPage'
import security from '@/security'
import { createGetParams, createPostParams } from '@/rest'
import {
  getRm,
  getNumeralFromRm,
} from '@/utils'

const formTitle = 'Ifinancing Transfer'

export default {
  extends: FormPage,
  data () {
    return {
      amount: 0.00,
      breadcrumbs: [
        {
          text: 'Operation Kits', disabled: false, to: '/operation/operation_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      transferDetails: {
        fields: {
          ecash: {
            name: 'E-recharge balance',
          },
          dealer: {
            component: () => import(/* webpackChunkName: "dealerField" */ '@/components/DealerField'),
            name: 'To dealer Id',
            props: {
              persistentHint: true,
              label: 'Enter Dealer Id',
              noSelf: true,
              claimer: security.me.claimer(),
              filterClaimer: true,
              required: true,
              rules: [val => !!val || 'Dealer Id required!'],
            },
          },
          package: {
            component: () => import(/* webpackChunkName: "iFinancingPackagesField" */ '@/components/IfinancingPackagesField'),
            name: 'iFinancing Plan',
          },
          requiredEcash: {
            name: 'Transfer Amount',
            strong: true,
          },
        },
        title: 'E-recharge transfer details',
      },
      submission: {
        fields: {
          dealer: {
            name: 'To dealer Id',
          },
          package: {
            name: 'iFinancing plan',
            strong: true,
          },
          chargeable: {
            emphasize: true,
            name: 'Transfer amount',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    formSteps: function () {
      return {
        transferDetails: this.transferDetails,
        submission: this.submission,
      }
    },
    ecash: function () {
      return this.transferDetails.fields.ecash
    },
    package: function () {
      return this.transferDetails.fields.package
    },
    requiredEcash: function () {
      const newFee = this.package.value && this.package.value.loan
        ? Number(this.transferDetails.fields.package.value.loan) : 0
      const requiredEcash = newFee
      return requiredEcash.toFixed(2)
    },
  },
  mounted: function () {
    const params = createGetParams({
      erecharge: true,
    })
    this.$rest.get('getDealerResources.php', params).then(function (response) {
      this.ecash.value = getRm(response.data.erecharge)
    }.bind(this)).catch(error => {
      this.ecash.error = error.message
    })
  },
  watch: {
    'package.value': {
      handler (val) {
        if (!val || !val.code) return
        const requiredEcash = this.requiredEcash
        this.transferDetails.fields.requiredEcash.value = getRm(requiredEcash)
        if (requiredEcash > getNumeralFromRm(this.ecash.value)) {
          this.transferDetails.fields.requiredEcash.error = 'Insufficient balance!'
        } else {
          this.transferDetails.fields.requiredEcash.error = null
        }
        this.submission.fields.chargeable.value = getRm(Number(requiredEcash))
        this.submission.fields.package.value = this.package.value.name
      },
      deep: true,
    },
    'transferDetails.fields.dealer.value': function (val) {
      this.transferDetails.fields.requiredEcash.value = null
      this.submission.fields.dealer.value = val
    },
  },
  methods: {
    // Override
    async submit () {
      this.amount = Number(getNumeralFromRm(this.transferDetails.fields.requiredEcash.value)).toFixed(2)
      const params = createPostParams({
        action: 'transferiFinancing',
        payload: {
          target: this.transferDetails.fields.dealer.value,
          amount: this.amount,
          plan: this.transferDetails.fields.package.value.code,
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to transfer e-recharge was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
  },
}
